import styled from "styled-components";

export const Photo = styled.div`
  /* == Mobile ===  */

  img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    border-radius: 0.25rem;
    object-fit: cover;
  }
  /* == Small desktop ===  */
  @media (min-width: 768px) {
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    max-height: 700px;
    max-width: 700px;

  }
`;
