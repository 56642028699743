import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

const getData = graphql`
  query {
    site {
      siteMetadata {
        siteTitle: title
        siteDesc: description
        image
        siteUrl
      }
    }
  }
`;

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(getData);

  const { image, siteUrl, siteTitle, siteDesc } = site.siteMetadata;

  return (
    <Helmet htmlAttributes={{ lang: "sl" }} title={`${siteTitle}`}>
      <meta name="description" content={siteDesc} />
      <meta name="image" content={image} />
      {/* Facebook card */}
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />p
      <meta property="og:title" content="domacija-zupancic.si" />
      <meta property="og:description" content={siteDesc} />
      <meta property="og:image" content={`${siteUrl}${image}`} />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" />
      <link
        rel="stylesheet"
        href="https://unpkg.com/@coreui/icons/css/brand.min.css"
      ></link>
    </Helmet>
  );
}

SEO.defaultProps = {
  title: `Domačija Zupančič`,
  lang: `sl`,
  description: `Ob obisku razstave se lahko sprostite v prelepi okolici in okusite domačo hrano iz njihove ekološke kmetije.
  Za popolno doživetje in potop v ta edinstven košček narave je na voljo nastanitev v mostiščarski hiši in nočno druženje s črnim močerilom.`,
};

SEO.propTypes = propTypes;

export default SEO;
