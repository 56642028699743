import React from "react";
import styled from "styled-components";
import { FaInstagram, FaPhone, FaRegEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <Wrapper>
      <div className="content">
        <div className="left-column">
          <div className="location">
            <a
            className="maps-link"
              href="http://www.google.com/maps/place/45.57282091416569,15.149796098852109"
              target="_blank"
            >
              Domačija Zupančič, Jelševnik 8, 8340 Črnomelj
            </a>
          </div>
        </div>

        <div className="right-column">
          <div className="contact">
            <div className="soc-container">
              <FaRegEnvelope className="icon" />
              <p>info@domacija-zupancic.si</p>
            </div>

            <div className="soc-container">
              <FaPhone className="icon" />
              <p>+386 31 258 698</p>
            </div>

            <div className="soc-container">
              <FaInstagram className="icon" />
              <p>domacijazupancic</p>
            </div>
          </div>
        </div>
      </div>

      <div className="info-bar">
        <p>© 2022 Domačija Zupančič, vse pravice pridržane </p>
      </div>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.footer`
  /* == Mobile ===  */
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  margin-top: 2rem;
  width: 95vw;

  .maps-link {
    margin: 1rem 0 0 0;
  }
  .info-bar {
    /* border: 1px solid blue; */
  }

  .content {
    /* border: 1px solid red; */
    height: 200px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .left-column {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .location {
        font-weight: 700;
        display: flex;
        flex-direction: column;
        /* height: 400px; */
      }
    }

    .right-column {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .contact {
        font-weight: 700;

        .soc-container {
          display: flex;
          justify-content: spread-around;
          align-items: center;
          p {
            margin-left: 0.25rem;
          }
        }
      }
    }
  }

  p,
  a {
    text-decoration: none;
    color: #4f4d5d;
    font-size: 0.75rem;
  }

  /* == Small desktop ===  */
  @media (min-width: 768px) {
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    width: 75vw;
    p,
    a {
      font-size: 1rem;
    }
  }
`;
