import React from "react";
import styled from "styled-components";
import zupancicLogo from "../../images/zupancic-logo.png";
import { Photo } from "./../../styles/StyledComponents/Photo";
import { FaInstagram, FaPhone, FaRegEnvelope } from "react-icons/fa";

const Header = ({ setLang }) => {
  const handleLangClick = lang => {
    localStorage.setItem("lang", lang);
    setLang(lang);
  };
  return (
    <Wrapper>
      <div className="left-column"></div>
      <div className="center-column">
        <Photo>
          <img src={zupancicLogo} alt="zupancic" />
        </Photo>
      </div>
      <div className="right-column">
        <div className="contact">
          <div className="lang-select">
            <div className="lang-button" onClick={() => handleLangClick("si")}>
              SI
            </div>
            <span> / </span>
            <div className="lang-button" onClick={() => handleLangClick("en")}>
              EN
            </div>
          </div>
          <div className="soc-container">
            <FaRegEnvelope className="icon" />
            <a href="mailto:info@domacija-zupancic.si">info@domacija-zupancic.si</a>
          </div>

          <div className="soc-container">
            <FaPhone className="icon" />
            <p>+386 31 258 698</p>
          </div>

          <div className="soc-container">
            <FaInstagram className="icon" />
            <a href="https://instagram.com/domacijazupancic" target="_blank">domacijazupancic</a>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.nav`
  /* == Mobile ===  */
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  padding-bottom: 1rem;

  a {
    text-decoration: none;
    color: #4f4d5d;
  }

  .center-column {
  }
  .right-column {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    .soc-container {
      display: flex;
      justify-content: spread-around;
      align-items: center;
      p {
        margin-left: 0.25rem;
      }
    }

    .lang-select {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-left: -1rem;

      .lang-button {
        margin: 0 1rem;
        cursor: pointer;
      }
    }
    /* border-bottom: 1px solid gray; */
  }

  /* == Small desktop ===  */
  @media (min-width: 768px) {
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    padding-bottom: 0;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    position: fixed;
    top: 0;
    width: 100%;
    height: 150px;
    background: #fffdfa;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .column {
      border: 1px solid red;
    }

    .center-column {
      display: flex;
      justify-content: center;
      align-items: center;
      ${Photo} {
        cursor: pointer;
        height: auto;
        width: 240px;
      }
    }

    .right-column {
      display: flex;
      justify-content: center;
      align-items: center;
      .contact {
        font-weight: 700;

        .soc-container {
          display: flex;
          justify-content: spread-around;
          align-items: center;
          p {
            margin-left: 0.25rem;
          }
        }
      }
    }
  }
`;
